import Vue from 'vue'
import ElementUI from 'element-ui';
import axios from './axios'
import 'element-ui/lib/theme-chalk/index.css';
import dataV from '@jiaminghi/data-view'
import router from './router.js';
import { website } from '@/config.js'
import App from './App.vue'
import './styles/common.scss'
import '@/utils/es6'
import '@/mock/'
//导入主题文件
import '@/theme/index.js'
import Cookies from "js-cookie"
import md5 from 'js-md5';

Vue.config.productionTip = false
window.axios = axios;
document.title = website.title
Vue.use(ElementUI);
Vue.use(window.AVUE);
Vue.prototype.$website = website;
Vue.prototype.$md5 = md5;
Vue.use(dataV)

let pathname = window.location.pathname
if(pathname.indexOf("/view/") != -1 || pathname.indexOf("/login") != -1){
	//不用账号密码就可以预览
}else{
	//不是预览界面需要登录
	const login_cache = Cookies.get("xcchlogin")
	if(login_cache){
		//有登录缓存允许登录
	}else{
		//跳转登录页面
		window.location.href="/login"
	}
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
